<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-form
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card>
              <b-row>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="title"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`title.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model="form.title[k]"
                            :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="body"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`body.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model="form.body[k]"
                            :options="snowOption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="meta_description"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`meta_description.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.meta_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.meta_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-textarea
                            v-model="form.meta_description[k]"
                            :placeholder="$t('general.field_lang', { field: $t('labels.meta_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols="12"
            xl="3"
            md="4"
          >
            <b-card>
              <b-row>
                <b-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="image"
                    :name="$t('labels.image')"
                    rules="required"
                  >
                    <ImagePreview
                      v-model="image"
                      :label="$t('labels.image')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" />
              </b-row>
              <hr>
              <b-col
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="status"
                  :name="$t('status')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('status')"
                  >
                    <v-select
                      v-model="form.status"
                      :dir="$store.state.appConfig.layout.direction"
                      label="name"
                      :options="statuses"
                      :reduce="item => item.id"
                      :placeholder="$t('status')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <hr>
              <b-row>
                <b-col cols="12 text-center">
                  <LoadingButton />
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'

export default {
  mixins: [formMixin],
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      image: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        body: {
          en: null,
          ar: null,
        },
        meta_description: {
          en: null,
          ar: null,
        },
        image: null,
        layout_name: 'default',
        status: null,
      },
    }
  },
  computed: {
    statuses() {
      return [
        {
          id: 'published',
          name: this.$t('statuses.published'),
        },
        {
          id: 'draft',
          name: this.$t('statuses.draft'),
        },
        {
          id: 'archived',
          name: this.$t('statuses.archived'),
        },
      ]
    },
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            body: data.body,
            meta_description: data.meta_description_value,
            status: data.status,
          }
          this.image = data.image_path
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },

}
</script>
